/* eslint-disable sort-keys */

import { type Bundle } from "ycb";
import * as benji from "./benji";

export const Features = {
  /**
   * Enable affilimate for shopping hub and articles
   * @author pkasi
   */
  affilimate: null,

  /**
   * Enable announcement banner preview
   * @author jkoenig02
   */
  announcementBannerPreview: null,

  /**
   * Enable CDS widgets
   * @author mjeanneret
   */
  cdsWidgets: null,

  /**
   * Disable NCP validation to make article migation for other properties easier
   * @author gracelin
   */
  disableNCPValidation: null,

  /**
   * Enable in-body ads for articles
   * @author cwang04
   */
  inBodyAd: null,

  /**
   * Display mid center ads on modal pages for desktop
   * @author cwang04
   */
  desktopInBodyAd: null,

  /**
   * Enable Exp Preview pages
   * @author chriss
   */
  expPreview: null,

  /**
   * Enable Yahoo Knowledge Q&A module
   * @author ycchien
   */
  knowledgeQA: null,

  /**
   * Enable lazy loading of ads
   * @author clicata
   */
  lazyAds: null,

  /**
   * Enable my finance for articles
   * @author cwang04
   */
  myFinance: null,

  /**
   * Enable nav preview
   * @author jkoenig02
   */
  navPreview: null,

  /**
   * Display next article bar
   * @author cmorri
   */
  nextArticleBar: null,

  /**
   * Display next article bar
   * @author pkasi
   */
  otelBrowser: null,

  /**
   * Display next article bar
   * @author pkasi
   */
  otelBrowserDebug: null,

  /**
   * Enable perf debug bar
   * @author jlin14
   */
  perf: null,

  /**
   * Enable preview mode for editors
   * @author kfay
   */
  preview: null,

  /**
   * Enable seamless article scrolling
   * @author dweaver
   */
  seamless: null,

  /**
   * Use default subnav
   * @author achen09
   */
  presetSubnav: null,

  /**
   * Show provider logo in articles
   * @author dliu01
   */
  providerLogo: null,

  /**
   * Blue Links for Quick Overview and Mini Modules for bucket testing, will also be added to source test/control to affiliate link for revenue tracking .
   * @author timliao
   */
  quickOverviewBlueLinkBucketControlSource_ssr: null,
  quickOverviewBlueLinkBucketTestSource_ssr: null,

  /**
   * Pass through for benji features
   */
  ...benji.FEATURES,
};

export type FeaturesMap = Partial<Record<keyof typeof Features, boolean>>;

export type FeaturesConfig = {
  features: FeaturesMap;
};

export const config: Bundle<FeaturesConfig> = [
  {
    settings: ["main"],
    features: {
      affilimate: false,
      cdsWidgets: false,
      inBodyAd: true,
      myFinance: true,
      otelBrowser: true,
      otelBrowserDebug: false,
      presetSubnav: false,
      providerLogo: false,
      seamless: true,
    },
  },
  {
    settings: ["site:autos,tech"],
    features: {
      providerLogo: true,
    },
  },
  {
    settings: ["site:autos"],
    features: {
      presetSubnav: true,
    },
  },
];
